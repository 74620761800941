<template>
  <div style="display: flex; flex-direction: column; height: 100%">
    <ReminderPanel :toggleDialog="toggleReminder" />

    <div style="flex: 1 1">
      <div style="flex: 1" class="mt-3">
        <div v-if="contact" style="display: grid" class="mx-2">
          <div style="grid-area: 1/1/1/1; justify-self: start">
            <div class="tag-container" v-if="call.contact_id!='family'">
              <ContactPopup
                v-if="call.contact_id && (contact.ph1 || contact.ph2 || contact.ph3)"
                dense
                :contact_id="parseInt(call.contact_id)"
                :tooltip="false"
              />
              <UnknownPopup
                v-if="call.contact_id && contact.phone_num"
                :phoneNum="contact.phone_num"
                :dense="true"
              />
             
              <!-- List of Tags -->
              <!-- <div v-if="call.contact_id && can_manual_tagging" class="add-tag-icon" @click="toggleTagPopup">
                <v-icon color="red" size="30">mdi-tag-plus</v-icon>
              </div> -->
              <div 
               :class="(massTagApply)?'display-top':'shared-overlay'"
              v-if="showTagPopup">
                <TagPopup 
                   v-if="showTagPopup"
                  :tags="filteredTags" 
                  :isMassTag="massTagApply"
                  @applyTag="toggleSelectionTag" 
                  @closeMenu="showTagPopup=false"
                  >
                </TagPopup>
                <div v-if="massTagApply">
                  <v-textarea v-model="massTagMessage"
                  variant="solo"
                  placeholder="Message (optional)" 
                  rows="1" auto-grow 
                  style="margin-top: 3%; width: max-content;">
                  <template v-slot:append-inner>
                  <div @click="applyMassTagEmit">
                    <v-tooltip activator="parent" location="bottom">Apply Mass Tag</v-tooltip>
                    <v-icon size="28" color="success" style="cursor: pointer; text-align: start;">mdi-tag-check</v-icon>
                  </div>
                </template>
                <template v-slot:append>
                  <v-btn elevation="5" @click="closeMassTagPopup" icon size="30">
                    <v-tooltip activator="parent" location="bottom">Close</v-tooltip>
                    <v-icon size="28" color="red" style="cursor: pointer; text-align: end;">mdi-close-circle</v-icon>
                  </v-btn>
                </template>
                </v-textarea>
                </div>
              </div>
             <!-- Displaying List of tags for contact's -->
                <template v-for="tagId in selectedTags" :key="tagId">
                  <template v-if="tagsHashnew[tagId]">
                          <v-tooltip :location="'bottom'" :text="tagsHashnew[tagId].tag_name">
                            <template v-slot:activator="{ props }">
                              <div v-bind="props">
                                <v-btn
                                size="25" color="white"  elevation="3" icon
                                >
                                <v-icon
                                  size="20"
                                  :color="tagsHashnew[tagId].tag_color"
                                  @click="toggleSelectionTag(tagId)"
                                >
                                <!-- remove tag on click -->
                                  mdi-{{ tagsHashnew[tagId].tag_icon }}
                                </v-icon>
                                </v-btn>
                              </div>
                            </template>
                          </v-tooltip>
                  </template>
                </template>
            </div>
          </div>
          <div style="grid-area: 1/1/1/1">
            <div class="displayCenter" style="margin-top: 10px; padding: 0px;">
              <div v-if="familyMembers.length>1" class="d-flex">
                <!-- Family Member Circles -->
                <div 
                  v-for="(member, index) in familyMembers" 
                  :key="index" 
                  class="mx-1" 
                  style="position: relative;"
                  @mouseover="hoveredMember = index"
                  @mouseleave="hoveredMember = hoveredMember === index ? null : hoveredMember"
                >
                  <div v-if="hoveredMember === index" class="tooltip-box">
                    {{ member.full_name }}
                  </div>
                

                  <v-avatar
                    :size="selectedIndex === index ? '2.8rem' : '2.3rem'"
                    v-bind:color="getContactColor(contactsHash[member.contact_id].contact_type)"
                    class="circle-style"
                    :class="{ 'selected-circle': selectedIndex === index, 'hovered-circle': hoveredMember === index }"
                    :style="{
                      marginLeft: selectedIndex === index ? '-5px' : '-15px',
                      marginRight: selectedIndex === index ? '5px' : '-15px'
                    }"
                    @click="toggleSize(index); viewFamilyMember(member)"
                    @mouseover="hoveredMember = index"
                    @mouseleave="hoveredMember = hoveredMember === index ? null : hoveredMember"
                  >
                    <span class="avatar-text" >
                      {{ member.first_name.substring(0, 2).toUpperCase() }}
                    </span>
                  </v-avatar>

                  <div v-if="selectedIndex === index && hoveredMember === index" class="icon-container">
                    <v-tooltip top>
                      <template v-slot:activator="{ props }">
                        <v-icon v-bind="props" size="15" @click="createInternalConvo">
                          mdi-plus
                        </v-icon>
                      </template>
                      <span>Create new convo on this contact</span>
                    </v-tooltip>

                    <v-tooltip top>
                      <template v-slot:activator="{ props }">
                        <v-icon v-bind="props" style="margin-left: 5px" size="15" @click="showCommConvos">
                          mdi-chat
                        </v-icon>
                      </template>
                      <span>View convos on this contact</span>
                    </v-tooltip>
                  </div>
                </div>
                

                <!-- ALL Circle -->
                <div 
                  class="all-circle-container"
                  @mouseover="hoveredMember = 'all'"
                  @mouseleave="handleAllMouseLeave"
                  @click.stop="() => {selectedIndex=null; selectedAllFamilyButton = true; viewAllFamilyConvos();}"
                >
                  <!-- Tooltip -->
                  <div v-if="hoveredMember=='all'" class="tooltip-box-all">
                    All family conversations
                  </div>

                  <v-avatar
                    :size="selectedAllFamilyButton === true ? '2.8rem' : '2.3rem'"
                    color="grey darken-2"
                    class="circle-style"
                    :class="{ 'selected-circle': selectedAllFamilyButton === 'all', 'hovered-circle': hoveredMember === 'all' }"
                  >
                    <span class="avatar-text">ALL</span>
                  </v-avatar>
                </div>
              </div>
              <div v-else>
                <v-menu
                  open-on-hover
                  :close-on-content-click="false"
                  close-delay="500"
                  max-width="60"
                  min-width="15"
                  max-height="25"
                  offset-y
                  top
                  right
                  offset-x
                  style="overflow-y: hidden"
                >
                  <template v-slot:activator="{ props }">
                    <div
                      v-bind="props"
                      :class="
                        highlightedItem === 'contact' ? 'outlined rounded' : ''
                      "
                    >
                      {{ phoneFormatTitle(contact.full_name) }}
                    </div>
                  </template>
                  <div>
                    <v-tooltip top>
                      <template v-slot:activator="{ props }">
                        <v-icon
                          v-bind="props"
                          size="15"
                          @click="createInternalConvo"
                        >
                          mdi-plus
                        </v-icon>
                      </template>
                      <span>Create new convo on this contact</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ props }">
                        <v-icon
                          v-bind="props"
                          style="margin-left: 5px"
                          size="15"
                          @click="showCommConvos"
                        >
                          mdi-chat
                        </v-icon>
                      </template>
                      <span>View convos on this contact</span>
                    </v-tooltip>
                  </div>
                </v-menu>
              </div>
            </div>
          </div>
          <div v-if="call.contact_id!='family'" style="grid-area: 1/1/1/1; justify-self: end">
            <v-btn-toggle borderless>
              <!-- cell phone button / endpoint button -->
              <v-tooltip
                v-if="contact.ph1 && contact.ph1"
                location="bottom"
                :text="phoneFormat(contact.ph1)"
              >
                <template v-slot:activator="{ props }">
                  <v-icon
                    v-bind="props"
                    class="ml-2"
                    color="green darken 1"
                    @click="phoneCall(contact.full_name, contact.ph1)"
                    >mdi-cellphone</v-icon
                  >
                </template>
              </v-tooltip>
              <!-- home phone button -->
              <v-tooltip
                v-if="contact.ph2 && contact.ph2"
                location="bottom"
                :text="phoneFormat(contact.ph2)"
              >
                <template v-slot:activator="{ props }">
                  <v-icon
                    v-bind="props"
                    class="ml-2"
                    color="green darken 1"
                    @click="phoneCall(contact.full_name, contact.ph2)"
                    >mdi-home</v-icon
                  >
                </template>
              </v-tooltip>
              <!-- work phone button -->
              <v-tooltip
                v-if="contact.ph3 && contact.ph3"
                location="bottom"
                :text="phoneFormat(contact.ph3)"
              >
                <template v-slot:activator="{ props }">
                  <v-icon
                    v-bind="props"
                    class="ml-2"
                    color="green darken 1"
                    @click="phoneCall(contact.full_name, contact.ph3)"
                    >mdi-briefcase</v-icon
                  >
                </template>
              </v-tooltip>
              <v-tooltip
                v-if="contact.phone_num && contact.phone_num"
                location="bottom"
                :text="phoneFormat(contact.phone_num)"
              >
                <template v-slot:activator="{ props }">
                  <v-icon
                    v-bind="props"
                    class="ml-2"
                    color="green darken 1"
                    @click="phoneCall(contact.full_name, contact.phone_num)"
                    >mdi-phone</v-icon
                  >
                </template>
              </v-tooltip>
            </v-btn-toggle>
          </div>
        </div>
        <v-divider class="mt-1"></v-divider>
      </div>
    </div>
    <!-- This row holds the messenger header -->
    <!-- This row holds the message chat virtual scroller -->
    <div v-show="!showTagSettings" style="flex: 10 10; position: relative">
      <div
        v-show="allCalls"
        style="position: absolute; top: 0; left: 0; bottom: 0; right: 0"
      >
        <DynamicScroller
          :items="allCalls"
          key-field="comm_participant_id"
          style="height: 100%"
          :buffer="20000"
          ref="scroller"
         :min-item-size="54"
        
        >
          <template v-slot="{ item, index, active }">
            <DynamicScrollerItem :item="item" :active="active" :size-dependencies="[
            item.message,
          ]"
          :data-index="index"
          :data-active="active">
              <FDMessage
                :message="item"
                :prev="index === 0 ? null : allCalls[index - 1]"
                :index="index"
                :active="active"
                :contact="getHeaderInfo(item.contact_id)"  
              />
            </DynamicScrollerItem>
          </template>
        </DynamicScroller>
      </div>
    </div>
    <!-- this row holds the message box -->
    <div v-show="!showTagSettings" style="flex: 1 1; position: relative">
      <v-overlay
        absolute
        :value="call && !call.contact_id"
        :opacity="0.08"
      ></v-overlay>
      <FDMessengerBox
        v-if="call.contact_id"
        :call="call"
        :contact="contact"
        :mostRecentNumber="mostRecentCall"
        :lastReceivedMsg="lastReceivedMsg"
        @reminderClicked="reminderClicked()"
      />
    </div>
   <!-- It will show Tag Settings -->
   <div v-if="showTagSettings && call.contact_id" style="flex: 10 10; position: relative; overflow-y:auto;">
      <div style="position: absolute; top: 0; left: 0; bottom: 0; right: 0">
       <TagSettings @closeTagSettings="showTagSettings=false"></TagSettings>
      </div>
    </div>
</div>
</template>

<script>
import FDMessage from "@/components/FrontDesk/FDMessage";
import FDMessengerBox from "@/components/FrontDesk/FDMessengerBox";
import ReminderPanel from "@/components/FrontDesk/ReminderPanel";
import MiscService from "@/services/miscService";
import ConvoService from "@/services/convoService";
import ContactPopup from "@/components/Avatar/ContactPopup";
import UnknownPopup from "@/components/Avatar/UnknownPopup";
import { mapGetters,mapMutations,mapState,mapActions } from "vuex";
import EventBus from "@/eventBus";
import { phoneFormat, phoneFormatTitle } from "@/util/util";
import TypeTagForm from "../AdminSettings/TypeTagForm.vue";
import TagSettings from "./TagSettings.vue";
import TagPopup from "../TagPopup.vue";
import { differenceInHours, parseISO } from 'date-fns';
export default {
  name: "Messenger",
  components: {
    FDMessage,
    FDMessengerBox,
    ContactPopup,
    UnknownPopup,
    ReminderPanel,  
    TypeTagForm,
    TagSettings,
    TagPopup
  },
  props: {
    allCalls: { type: Array },
    contact: { type: Object, default: null },
  },
  emits: [],
  data() {
    return {
      loading: true,
      toggleReminder: false,
      toggleConvo: false,
      convoReference: null,
      mostRecentCall: "",
      highlightedItem: null,
      highlightedComm: null,
      lastReceivedMsg: null,
      selectedTags: [], 
      showTagPopup : false,
      massTagApply : false,
      massTagMessage : "",
      showTagSettings :false,
      showFamilyDialog : false,
      familyMembers: [],
      hoveredMember: null,
      selectedIndex: null,
      selectedAllFamilyButton: false,
      familyHead: null,
    };
  },
  computed: {
    ...mapGetters("frontdesk", ["getAllCalls", "call","odFilterSelectedValue","getOdTag"]),
    ...mapGetters(['clientId']),
    ...mapState(['contactState']),   
    ...mapGetters("people", ["contactsHash","getHeaderInfo","getContactColor"]),
    ...mapGetters("tags", [ "tagsHashnew","tags","tagsHash","can_manual_tagging"]),
    attachedTagIds() {
      return this.call.tag_id || [];
    },
    filteredTags() {
      const unselected = this.tags.filter(
        (tag) => !this.selectedTags.includes(tag.tag_id) && !tag.unlist && !tag.enable_auto// unlist tag only visible to already tagged contacts, if not it won't display and auto tag's not allowed for manual tagging
      );
      return unselected;
    },
   
  },
  watch: {
    "allCalls.length": function () {
      this.scrollToBottom();
      this.getMostRecentCall();
      EventBus.$emit("new_comm");
    },
    "contactState.contact_id" :  function(newID){
      if(newID){
        this.showTagSettings = false;
        this.showTagPopup = false;
        } 
        // update selected tags for new contact  
        if(this.call.tag_id){
          for(let i=0; i<this.call.tag_id.length;i++){
            this.selectedTags[i] = this.call.tag_id[i];
          }
        }else{
          this.selectedTags = [];
        }
        console.log("Selected Tags"+this.selectedTags);
    },
    "call":{
      immediate: true, // Ensures it runs as soon as `call` is available
      handler(newCall) {
        if (newCall && newCall.contact_id) {
          this.selectedIndex = this.familyMembers.findIndex(
            (member) => member.contact_id === newCall.contact_id
          );
        }
      },
    },
    contactState(){
      this.selectedTags = [];// while changing contact state we initiate this as empty, afterwards it will populate in above async function
      this.showTagSettings = false;
    }
  },

  created() {
    EventBus.$on("familyList",data=>{
      this.familyMembers = data.family;
      this.familyHead = data.familyHead;
    });
    EventBus.$on("highlightConvoComm", (convo) => {
      this.highlightedItem = "comm";
      this.highlightedComm = convo;
      const scroller = this.$refs.scroller;
      if (scroller) scroller.scrollToItem(convo);
    });

    EventBus.$on("highlightContact", () => {
      this.highlightedItem = "contact";
    });

    EventBus.$on("clearHighlight", () => {
      this.highlightedItem = null;
      this.highlightedComm = null;
    });

    EventBus.$on("submitConvo", async (content, comm) => {
      const data = {
        message: content,
        contact_id: this.call.contact_id,
        conversation_id: comm,
      };

      await ConvoService.createInternalConvo(data);
    });
    this.getCalls();
    if (this.allCalls && this.allCalls.length > 0) this.getMostRecentCall();
  },

  beforeUnmount() {
    EventBus.$off("highlightConvoComm");
    EventBus.$off("highlightContact");
    EventBus.$off("clearHighlight");
    EventBus.$off("familyList");
    EventBus.$off("submitConvo");
  },

  methods: {
    phoneFormat,
    ...mapMutations(["setContact"]),
    ...mapMutations(['changeRightPanelStatus']),
    ...mapMutations("frontdesk", ["changeCall", "setAllCalls"]),
    ...mapMutations("convos", ["setInternalConvos"]),
    ...mapGetters("people", ["contactTypes"]),
    ...mapActions("tags",["addTagforContact","removeTagForContact"]),
    phoneFormatTitle,
    getMostRecentCall() {
      let recentInbound = null;
      let recentOutbound = null;
      this.lastReceivedMsg = null;
      for (let i = this.allCalls.length - 1; i >= 0; i--) {
        if (
          (this.allCalls[i].comm_direction == "incoming" ||
            this.allCalls[i].comm_direction == "inbound") &&
          this.isTextable(this.allCalls[i])
        ) {
          if (recentInbound == null) {
            recentInbound = this.allCalls[i];
          }
          if (this.allCalls[i].comm_direction == "incoming") {
            this.lastReceivedMsg = this.allCalls[i];
          }
        } else if (
          this.isTextable(this.allCalls[i]) &&
          recentOutbound == null
        ) {
          recentOutbound = this.allCalls[i];
        }
      }
      this.mostRecentCall =
        recentInbound != null
          ? recentInbound.phone_num
          : recentOutbound != null
          ? recentOutbound.phone_num
          : null;
    },
    isTextable(message) {
      return (
        (message.phone_num == this.call.ph1 && this.call.textable1 == 1) ||
        (message.phone_num == this.call.ph2 && this.call.textable2 == 1) ||
        (message.phone_num == this.call.ph3 && this.call.textable3 == 1)
      );
    },
    // get the calls for this participants
    getCalls() {
      this.loading = false;
      this.scrollToBottom();
    },
    toggleSize(index) {
      this.selectedIndex = index;
      this.selectedAllFamilyButton = false;
    },
    phoneCall(name, number) {
      let tagId =0;
      if(this.odFilterSelectedValue && this.getOdTag && this.getOdTag.enable_auto){
          tagId = this.getOdTag.tag_id;  // sending tag_id for conversation auto tag 
      }
      EventBus.$emit("call-event", { name, number, tagId});
    },
    scrollToBottom() {
      console.log("start of scrollToBottom");
      this.$nextTick(() => {
        if (!this.$_scrollingToBottom) {
          this.scrollToPosition(999999999);
          this.$_scrollingToBottom = true;
        }
      });
      console.log("end of scrollToBottom");
    },
    reminderClicked() {
      console.log("start of reminderClicked");
      this.toggleReminder = !this.toggleReminder;
      //console.log(this.toggleReminder);
    },
    createInternalConvo() {
      EventBus.$emit("createConvo", null);
      this.changeRightPanelStatus(true);
    },
    showCommConvos() {
      this.changeRightPanelStatus(true);
      EventBus.$emit("showContactConvos");
    },
    checkHighlight(item) {
      return (
        this.highlightedItem === "comm" &&
        this.highlightedComm.convo_references[0].comm_participant_id ===
          item.comm_participant_id
      );
    },
    scrollToPosition(position) {
      console.log("start of scrollToPosition");
      if (!this.$refs.scroller) return;
      const scroller = this.$refs.scroller.$el;
      scroller.scrollTop = position;
      requestAnimationFrame(() => {
        scroller.scrollTop = position;
        setTimeout(() => {
          scroller.scrollTop = position;
          this.$_scrollingToBottom = false;
        }, 100);
      });

      console.log("end of scrollToPosition");
    },
  
  toggleTagPopup(){
      this.showTagPopup=!this.showTagPopup
    },
    closeMassTagPopup(){ 
      this.showTagPopup=false;
      this.massTagApply = false;
      this.selectedTags = [];
      EventBus.$emit("tagModelClosed");
    },
    
    applyMassTagEmit(){
     // it only passing selected tags
     const data = {selectedTags : this.selectedTags, massTagMessage : this.massTagMessage}
      EventBus.$emit("mass-tags-list",data)// apply tag for group of contacts based on filter
      this.showTagPopup=false;
      this.massTagApply = false;
      this.massTagMessage = "";
      this.selectedTags = [];
    },
    toggleSelectionTag(item) {
      if (this.selectedTags.includes(item)) {
        // Remove item if already selected
        this.selectedTags = this.selectedTags.filter((i) => i !== item);
        if(!this.massTagApply){
          this.remove_TagForContact(this.contactState.contact_id,item);
        }
      } else {
        // Add item if not selected
        this.selectedTags.push(item);
        if(!this.massTagApply){
          this.addTagToContact(this.contactState.contact_id,item);
          this.showTagPopup = false;
        }
      }
    },
   async addTagToContact(contact_id,tag_id){
    try {//sending single tag id as array format.
      let tagArray = [];
      tagArray.push(tag_id);
      let data={contact_id : contact_id,tag_ids : tagArray}
      await this.addTagforContact(data);
      } catch (error) {
        console.error(error);
      }
    },
    async remove_TagForContact(contact_id,tag_id){
        try {
          let data={contact_id : contact_id, tag_id : tag_id};
          await this.removeTagForContact(data);
        } catch (error) {
          console.error(error);
        }
    },
    handleAllMouseLeave() {
      if (this.selectedIndex !== 'all') {
        this.hoveredMember = null;
      }
    },
    async viewFamilyMember(member) {
      console.log("isbci",member,this.getContactColor(member.contact_type));
      const contact = this.contactsHash[member.contact_id];
      const contactId =  contact.contact_id;  
      let allCalls;
      if (contact.contact_id == contact.first_name && contact.contact_id == contact.last_name && contact.contact_id == contact.phone_num) {
        console.log('get all calls for phone')
        allCalls = await MiscService.getAllCallsForPhone(contact.phone_num);
      } else {
        console.log('get all calls for contact')
        allCalls = await MiscService.getAllCallsForContact(contactId);
      }
      this.setAllCalls(allCalls.data);
      this.changeCall(contact);

      this.updateRightPanel(contactId);
    },
    async viewAllFamilyConvos() {
      let allCalls = [];
      
      for (const member of this.familyMembers) {
          const contact = this.contactsHash[member.contact_id];
          const contactId = contact.contact_id;
          let calls;

          if (contact.contact_id == contact.first_name && 
              contact.contact_id == contact.last_name && 
              contact.contact_id == contact.phone_num) {
              console.log('get all calls for phone');
              calls = await MiscService.getAllCallsForPhone(contact.phone_num);
          } else {
              console.log('get all calls for contact');
              calls = await MiscService.getAllCallsForContact(contactId);
          }

          // Append the retrieved calls to allCalls array
          allCalls.push(...calls.data);
      }
      allCalls.sort((a, b) => new Date(a.date)- new Date(b.date));
      this.setAllCalls(allCalls);
      this.changeCall({ contact_id: 'family' });
      this.updateRightPanel('forFamily');
    },
    async updateRightPanel(contact_id) {
      if(contact_id == 'forFamily'){
        let allconvos =[];
        for (const member of this.familyMembers){
          let _contact_id = parseInt(member.contact_id);
          let contact = this.contactsHash[member.contact_id];
          this.setContact(contact)
          let convos = await ConvoService.getClientInternalConvos(_contact_id);
          convos = convos.data;
          const conversationHash = this.allCalls
          convos.forEach((convo) => {
            let remainingHrs;
            if (convo.userReminder) {
              const givenDate = parseISO(convo.userReminder.reminder_date);
              const now = new Date();
              remainingHrs = differenceInHours(givenDate, now);
              convo.remaining_hr = remainingHrs;
            }
            else {
              convo.remaining_hr = null;
            }
            let conversation_id = convo.convo_references[0].conversation_id;
            conversationHash.forEach((convoHash) => {
              if (convoHash.comm_participant_id == conversation_id) {
                let jsonObject = Object.assign({}, convo.convo_references[0].conversation, convoHash);
                convo.comm_participant = jsonObject;
                if (jsonObject.call_id) {
                  convo.comm_participant.call = {}
                  convo.comm_participant.call.call_missed = jsonObject.call_missed
                  convo.comm_participant.call.call_uuid = jsonObject.call_uuid
                  convo.comm_participant.call.comm_ref = jsonObject.comm_ref
                  convo.comm_participant.call.date = convo.date_created
                }
                else {
                  convo.comm_participant.text_message = {}
                  convo.comm_participant.text_message.direction = jsonObject.comm_direction
                  convo.comm_participant.text_message.message = jsonObject.message
                  convo.comm_participant.text_message.date = convo.date_created
                }

              }
            })
          })
          allconvos.push(...convos);
        }
        allconvos.sort((a, b) => {
          if (a.remaining_hr === null) return 1;  // If a is null, push it to the end
          if (b.remaining_hr === null) return -1; // If b is null, push it to the end
          return a.remaining_hr - b.remaining_hr; // Otherwise, compare the values
        });

        this.setInternalConvos(allconvos);
        let contact = this.contactsHash[this.familyHead.contact_id];
        EventBus.$emit(
            "populateRightPanel",
            contact,
            allconvos
          );
        return;
      }
      let _contact_id = parseInt(contact_id);
      let contact = this.contactsHash[contact_id];
      this.setContact(contact)
      let convos = await ConvoService.getClientInternalConvos(_contact_id);
      convos = convos.data;
      const conversationHash = this.allCalls
      convos.forEach((convo) => {
        let remainingHrs;
        if (convo.userReminder) {
          const givenDate = parseISO(convo.userReminder.reminder_date);
          const now = new Date();
          remainingHrs = differenceInHours(givenDate, now);
          convo.remaining_hr = remainingHrs;
        }
        else {
          convo.remaining_hr = null;
        }
        let conversation_id = convo.convo_references[0].conversation_id;
        conversationHash.forEach((convoHash) => {
          if (convoHash.comm_participant_id == conversation_id) {
            let jsonObject = Object.assign({}, convo.convo_references[0].conversation, convoHash);
            convo.comm_participant = jsonObject;
            if (jsonObject.call_id) {
              convo.comm_participant.call = {}
              convo.comm_participant.call.call_missed = jsonObject.call_missed
              convo.comm_participant.call.call_uuid = jsonObject.call_uuid
              convo.comm_participant.call.comm_ref = jsonObject.comm_ref
              convo.comm_participant.call.date = convo.date_created
            }
            else {
              convo.comm_participant.text_message = {}
              convo.comm_participant.text_message.direction = jsonObject.comm_direction
              convo.comm_participant.text_message.message = jsonObject.message
              convo.comm_participant.text_message.date = convo.date_created
            }

          }
        })
      })
      convos.sort((a, b) => {
        if (a.remaining_hr === null) return 1;  // If a is null, push it to the end
        if (b.remaining_hr === null) return -1; // If b is null, push it to the end
        return a.remaining_hr - b.remaining_hr; // Otherwise, compare the values
      });
      console.log("convos", convos)

      this.setInternalConvos(convos);
        EventBus.$emit(
          "populateRightPanel",
          contact ? contact : { phone_num: contact_id },
          convos
        );
    },
  },
  mounted(){
    EventBus.$on("mass-tag",(data)=>{
        this.massTagApply = true;
        this.toggleTagPopup(); 
        this.selectedTags = [];
    });
    EventBus.$on("open-tag-settings",()=>{
      this.showTagSettings=true;
      this.showTagPopup = false;
    })
    
    if(this.contactState.contact_id){
         // update selected tags for new contact  
         for(let tagId in this.call.tag_id){
          if(!this.selectedTags.includes(tagId))
            this.selectedTags.push(tagId);
        }
        console.log("Selected Tags"+this.selectedTags);
    }
    
  },
  beforeUnmount() {
    EventBus.$off("highlightConvoComm");
    EventBus.$off("highlightContact");
    EventBus.$off("clearHighlight");
    EventBus.$off("submitConvo");
  },

};
</script>

<style scoped>
.outlined {
  background-color: var(--v-highlighted-base);
  border: 1px solid gray;
}

.rounded {
  border-radius: 2px;
}

.ml-2 {
  margin-left: 2px;
}

.tag-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.add-tag-icon {
  margin-right: 16px;
  cursor: pointer;
}

.shared-overlay {
 position: absolute;
 z-index:1;
 top:20%;
 /* left:20%; */
}
.display-top {
 position: absolute;
 z-index:1;
 top:30%;
}


.circle-style {
  cursor: pointer;
  transition: all 0.3s ease;
  margin-left: 15px;
  border: 2px solid transparent;
}

.all-circle-style {
  cursor: pointer;
  transition: all 0.3s ease;
  margin-left: 15px;
  margin-right: 15px;
  border: 2px solid transparent;
}

.avatar-text {
  font-size: 0.9rem;
  color: black;
}

.tooltip-box {
  position: absolute;
  top: -22px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.75rem;
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 3px 5px;
  border-radius: 5px;
  max-width: 140px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tooltip-box-all {
  position: absolute;
  margin-top: -22px;
  left: 67%;
  transform: translateX(-50%);
  font-size: 0.75rem;
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 3px 5px;
  border-radius: 5px;
  max-width: 140px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon-container {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

/* When hovered, the circle gets a black border */
.hovered-circle {
  border: 2px solid black !important;
}

/* When selected, the border becomes black */
.selected-circle {
  border: 2px solid black !important;
  margin-left: -5px !important;
  margin-right: -5px !important;
}

.all-selected-circle {
  border: 2px solid black !important;
}

/* Spacing for the ALL button */
.all-circle-container {
  cursor: pointer;
  transition: all 0.3s ease;
  margin-left: 15px; /* More spacing from other circles */
  margin-right: 15px; /* More spacing from other circles */
  position: relative;
}

.mx-1 {
    margin-right: 4px !important;
    margin-left: 20px !important;
}

</style>
